

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Groups, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

@Component({ })
export default class GroupsSelection extends Vue {
    @Groups.State('list') groupList;
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    @Prop() schedule_groups;

    allSelected = false;
    checkbox_color = '#812E81';
    selected_groups = [];
    groups_list = [];

    mounted(){
        this.selected_groups = [...this.schedule_groups];
        this.groups_list = cloneDeep(this.groupList);
        this.groups_list = this.groups_list.filter((group) => group.total_devices > 0);
        this.groups_list.sort((group1, group2) => group1.name.localeCompare(group2.name));
    }

    handleSelectChanged() {
        this.allSelected = this.selected_groups.length === this.groups_list.length;
        this.$emit('selectedGroupsChanged', this.selected_groups);
    }

    handleContinueClicked(){
        this.$emit('stepChanged', 2);
    }

    selectAll() {
        this.allSelected = !this.allSelected;
        this.selected_groups = [];

        if (this.allSelected) {
            this.selected_groups = this.groups_list.map((group) => group.id);
        }
        this.$emit('selectedGroupsChanged', [...this.selected_groups]);
    }

}

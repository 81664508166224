




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Schedules, User, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import API, { Types } from '@/modules/API';
import PropEditor from '@/components/PropEditor.vue';

@Component({ components: {
    PropEditor
}})
export default class SaveSchedule extends Vue {
    @Global.State('readonly_user') readonly_user;
    @User.State('project') project;
    @Schedules.Action('updateExistSchedule') updateSchedule;
    @Schedules.Action('runSchedulerInServer') runSchedulerInServer;
    @Schedules.State('schedules') schedules;
    
    @Prop() scheduleId;
    @Prop() schedule;

    save_disabled = false;

    mounted(){
        if (!this.schedule.name) this.save_disabled = true;
    }

    @Watch('schedule.name')
    setDisabled(){
        this.save_disabled = !this.$refs.nameInput['validate']();
    }

    requiredField(){
        return !!this.schedule.name || this.$t('This field is required');
    }

    duplicateName(){
        return this.schedules.some((schedule) => schedule.name.toLowerCase() === this.schedule.name.toLowerCase() && schedule.id !== this.schedule.id) && this.$t('This name already exists') || true;
    }

    async saveSchedule() {
        if (!this.schedule.priority) {
            if (this.schedules.length) {
                const current_priorities = this.schedules
                    .filter((schedule) => Number.isInteger(schedule.priority))
                    .map((schedule) => Number(schedule.priority));
                this.schedule.priority = Math.max(...current_priorities) + 1;
                if (this.schedule.priority === Infinity || this.schedule.priority === -Infinity) {
                    this.schedule['priority'] = 1;
                }
            }else {
                this.schedule['priority'] = 1;  
                await this.validateProjectHasSchedule();
            }
        }
        this.updateSchedule(this.schedule);

        (this.$refs.propEditor as PropEditor).save(
            this.schedules,
            {
                name: 'dashboard.schedules',
                objectID: this.project.id,
                objectType: Types.PROJECTS
            },
            () => { this.$router.push('/schedules'); }
        );

        this.runSchedulerInServer();
    }

    async validateProjectHasSchedule(){
        const url = 'https://yoqci9gzri.execute-api.us-east-1.amazonaws.com/dashboard-api/scheduler/update';
        const body = {
            project_id: this.project.id,
            company_id: this.project.company
        };

        try {
            const response = await API.externalAPI(url, 'POST', {}, body, true);
            if (response.status !== 200) console.log(response);
        } catch (e) {
            console.log(e);
        }
    }

}

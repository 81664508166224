

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Global, User, Groups, Schedules, Props } from '@/store';
import MessageDialog from '@/components/MessageDialog.vue';
import GroupsSelection from './components/GroupsSelection.vue';
import CurveSelection from './components/CurveSelection.vue';
import SaveSchedule from './components/SaveSchedule.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        GroupsSelection, 
        CurveSelection,
        SaveSchedule,
        MessageDialog
    }
})
export default class SchedulePage extends Vue {
    @Groups.Action('load') fetchGroups;
    @Groups.State('list') groupList;
    @Global.State('lang') lang;
    @User.State('project') project;
    @Schedules.Action('loadSchedules') loadSchedules;
    @Schedules.Action('loadUserCurves') loadUserCurves;
    @Schedules.State('schedules') schedules;
    @Schedules.Action('runSchedulerInServer') runSchedulerInServer;
    @Schedules.State('userCurves') userCurves;

    schedule_curves_map = new Map();
    curves_map = new Map();
    selectedGroups = [];
    groups_map = new Map();
    current_step = 1;
    scheduleId = '';
    loading = true;
    activeCurve = {
        color: '',
        id: ''
    };
    schedule = {
        groups: [],
        name: '',
        description: '',
        curves: [],
        id:
        '_' +
        Math.random()
            .toString(36)
            .substr(2, 9),
        active: 1
    };

    async mounted() {
        this.loading = true;
        try {
            this.scheduleId = this.$route.params.id;
            if (this.scheduleId !== 'create') this.changeCurrentStep(2);
            await this.loadData();
            this.setCurvesMap();
        }catch (error) {
            console.log('error in schedules mounted', error);
        }
        this.loading = false;
    }
    
    @Watch('userCurves', {deep: true, immediate: true})
    setCurvesMap(){
        this.curves_map = new Map();
        this.userCurves.forEach((curve) => this.curves_map.set(curve.id, curve));
    }

    @Watch('groupList.length')
    setGroupsListMap(){
        this.groupList.forEach((group) => this.groups_map.set(group.id, group.name));
    }

    @Watch('groupList.length')
    get selectedGroupsList(){
        return this.selectedGroups.map((group_id) => this.groups_map.get(group_id)).join(', ');
    }

    getSelectedCurvesList(){
        return [...this.schedule_curves_map.values()].map((curve) => curve.name).join(', ');
    }

    changeCurrentStep(step){
        this.current_step = step;
    }

    @Watch('project')
    async loadData() {
        if (this.project){
            await this.fetchGroups({ project_id: this.project.id });
            await this.loadSchedules({ projectId: this.project.id });
            await this.loadUserCurves();
            this.setGroupsListMap();
            this.setSchedule();
        }
    }

    setSchedule() {
        if (this.scheduleId && this.scheduleId !== 'create') {
            const schedule = this.schedules.find((schedule) => schedule.id === this.scheduleId);
            if (!schedule) return;

            this.schedule = cloneDeep(schedule);
            if (this.schedule.groups) this.selectedGroups = this.schedule.groups;
            this.setScheduleCurves();
        }
    }

    updateScheduleCurves(curves){
        this.schedule.curves = cloneDeep(curves);
        this.setScheduleCurves();
    }

    setScheduleCurves(){
        this.schedule_curves_map = new Map();
        [...new Set(this.schedule.curves.map((curve) => curve.id))]
            .map((curve_id) => this.curves_map.get(curve_id))
            .filter((curve) => curve)
            .forEach((curve) => this.schedule_curves_map.set(curve.id, curve));
    }

    setActiveCurveData(curve){
        this.activeCurve.id = curve.id;
        this.activeCurve.color = curve.color;
    }

    updateSelectedGroups(selected){
        this.selectedGroups = [...selected];
        this.schedule.groups = this.selectedGroups;
    }
}
